import ReactDOM from "react-dom";

import { install } from "resize-observer";

// Font Awesome
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

// Estilos Internos
import "./assets/scss/Style.scss";

// Helper Classes
// import "../node_modules/helper-style-classes"

// Estilos Externos
// import "../node_modules/animate.css"
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-slideshow-image/dist/styles.css";
import "../node_modules/react-vertical-timeline-component/style.min.css";

import App from "./App";

// Carregando todos os tipo de �cones Free do FontAwesome
library.add(fas, far, fab)

if (!window.ResizeObserver) install()

ReactDOM.render(<App />, document.getElementById("root"))
