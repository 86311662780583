import { useEffect, useRef, useState } from "react"
import Chart from "react-apexcharts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Collapse, Input, Label, Spinner } from "reactstrap"
import "./CollapseItem.scss"
// import noImage from "../assets/img/builder/no-image.png"
import noFlavour from "../assets/img/builder/no-flavour.png"
import pizzaStandard from "../assets/img/builder/pizza-standard.png"
import GeneralTools, { escapeSpecialChart, getUrlAWSManagerFile, onMessageDialog } from "../helpers/GeneralTools"
import Message from "./Message"

// import NumberControl from "./NumberControl/NumberControl"
import OptionCollapseItem from "./OptionCollapseItem/OptionCollapseItem"

import cursor from "../assets/img/icons/cursor.png"

const CollapseItem = (props) => {
  const {
    nro_item,
    title,
    subtitle,
    option,
    type,
    status,
    content,
    contentGraph,
    selectedGroup,
    selectedFlavour,
    selected = [],
    size,
    validations,
    onClick,
    onChangeAmount,
    onChangeIngredients,
    onChangeAmountFlavour,
    onChangeAmountComplement,
    onChangeAmountBeverage,
    comboProductId,
    selectedFlavourNumber = 1,
    selectedComboFlavourNumber = [],
    maxAdditionals = null,
    complementId = null,
    complement = null,
    qtyItems,
    selectFlavour,
  } = props
  const [statusCollapse, setStatusCollapse] = useState(status)
  const [filterConsult, setFilterConsult] = useState("")
  // const [selectedOptionals, setSelectedOptionals] = useState(0)

  const hasClickedRef = useRef(false)

  const onChangeCollapse = () => {
    setStatusCollapse(!statusCollapse)
  }

  const getAmountFlavoursSize = (size) => {
    return !!size ? size.qty_max_flavours : 1
  }

  const getClassValidation = () => {
    let needToValidate = ""

    if (!!validations) {
      if (!!nro_item && validations.find((validation) => validation.nro_item === nro_item && validation.type === type)) {
        needToValidate = "collapse-validation"
      }

      if (!!complement && validations.find((validation) => validation.id_complement === complement.id_complement && validation.type === type)) {
        needToValidate = "collapse-validation"
      }
    }

    return needToValidate
  }

  const defineOptionType = () => {
    let optionType = ""
    if (type !== "group") {
      if (!!size) {
        if (type === "size") {
          optionType = "radio"
        }

        if (type === "flavour") {
          if (size.qty_max_flavours === 1) {
            if (size.qty_min_flavours === 1) {
              return "radio"
            } else {
              return "selector"
            }
          } else if (size.qty_max_flavours > 1) {
            optionType = "selector"

            content.forEach((contentItem) => {
              if (!!contentItem.sizes) {
                // if (contentItem.sizes.find((item) => item.uuid_size === size.uuid_size).additional_size.qty_max_allowed > 1) {
                optionType = "numbercontrol"
                // }
              } else {
                if (contentItem.size.additional_size.qty_max_allowed > 1) {
                  optionType = "numbercontrol"
                }
              }
            })
          }
        }

        if (type === "ingredient") {
          return "selector"
        }

        if (type === "optional") {
          optionType = "selector"

          for (const contentItem of content) {
            if (!!contentItem.sizes) {
              const selectedSize = contentItem.sizes.find((eachSize) => eachSize.uuid_size === size.uuid_size)
              if (selectedSize.ingredient_size.qty_max_allowed > 1) {
                optionType = "numbercontrol"
                break
              }
            } else {
              if (contentItem.size.ingredient_size.qty_max_allowed > 1) {
                optionType = "numbercontrol"
                break
              }
            }
          }
        }

        if (type === "complement") {
          if (!!size.complement_size) {
            const { qty_max_allowed } = size.complement_size

            if (qty_max_allowed === 1) {
              optionType = "radio"
            } else if (qty_max_allowed > 1) {
              optionType = "selector"
              // optionType = "numbercontrol"

              content.forEach((contentItem) => {
                if (contentItem.size?.additional_size?.qty_max_allowed > 1) {
                  optionType = "numbercontrol"
                }
              })
            }
            return optionType
          } else {
            for (const contentItem of content) {
              optionType = size.qty_max_complements > 1 ? "selector" : "radio"

              const additionalSize = !!contentItem.size ? contentItem.size : contentItem.sizes?.find(({ uuid_size }) => uuid_size === size.uuid_size)
              if (!!additionalSize) {
                const { qty_max_allowed } = additionalSize.additional_size

                if (qty_max_allowed > 1) {
                  optionType = "numbercontrol"
                  break
                }
              }
            }
          }
        }
      } else {
        if (type === "size") {
          optionType = "radio"
        }

        if (type === "ingredient") {
          optionType = "selector"
        }

        if (type === "complement") {
          optionType = "radio"
        }

        if (type === "beverage") {
          if (qtyItems?.qty_max_beverage === 1) {
            optionType = "radio"
          } else {
            optionType = "numbercontrol"
          }
        }
      }
    } else if (type === "group") {
      optionType = "radio"
    }

    return optionType
  }

  const changeAdditionals = (item) => {
    onClick(item, maxAdditionals)
  }

  const changeAmountAdditionals = (amount, direction, type, additional) => {
    if (type === "flavour") {
      let qtySelected = selected.reduce(function (acc, seq) {
        return acc + seq.amount
      }, 0)
      switch (direction) {
        case "up":
          qtySelected++
          break
        case "down":
          qtySelected--
          break
        default:
          break
      }

      // setSelectedOptionals(qtySelected)
      onChangeAmount(amount, direction, type, additional)
    } else {
      let qtySelected = selected.reduce((tot, { amount = 1 }) => tot + amount, 0)
      if (amount > additional?.size?.ingredient_size?.qty_max_allowed) {
        Message.info("Quantidade máxima de opcionais atingida.")
        return
      }
      if (amount > additional?.size?.additional_size?.qty_max_allowed) {
        Message.info("Quantidade máxima de opcionais atingida.")
        return
      }
      if (direction === "up") {
        qtySelected++
      } else if (direction === "down") {
        qtySelected--
      }
      onChangeAmount(amount, direction, type, additional, complement)
    }
  }

  const onChangeAmountValue = (amount, direction, type, item) => {
    switch (type) {
      case "flavour": {
        onChangeAmountFlavour(amount, direction, type, item)
        break
      }
      case "size": {
        break
      }
      case "complement": {
        onChangeAmountComplement(amount, direction, type, item, complement)
        break
      }
      case "optional": {
        changeAmountAdditionals(amount, direction, type, item)
        break
      }
      case "ingredient": {
        break
      }
      case "addicional": {
        changeAmountAdditionals(amount, direction, type, item)
        break
      }
      case "beverage": {
        onChangeAmountBeverage(amount, direction, type, item)
        break
      }
      default: {
        break
      }
    }
  }

  const getSelectedAmountOptions = () => {
    switch (type) {
      case "flavour":
      case "optional": {
        return Array.isArray(selected) ? selected?.reduce((tot, { amount = 1 }) => tot + amount, 0) : 0
      }
      case "complement": {
        return selected?.reduce((tot, { additionals = [] }) => {
          return tot + additionals.reduce((tot, { amount = 0 }) => tot + amount, 0)
        }, 0)
      }
      default: {
        return 0
      }
    }
  }

  useEffect(() => {
    let filteredContent = content

    if (!!filterConsult) {
      filteredContent = content.filter((item) => `${item.nm_flavour}`.toLowerCase().includes(filterConsult))
    }
    //filtrando os selecionados
    if (type === "size") {
      filteredContent = filteredContent.map((item) => {
        const isSelected = !!selected && item.uuid_size === selected.uuid_size

        if (!!isSelected) {
          return {
            ...item,
            selected: true,
            amount: isSelected.amount,
          }
        }
        return {
          ...item,
          selected: false,
        }
      })
    }

    if (type === "adicional") {
      filteredContent = filteredContent.map((item) => {
        const isSelected = selected.find(({ uuid_additional }) => uuid_additional === item.uuid_additional)

        if (!!isSelected) {
          return {
            ...item,
            selected: true,
            amount: isSelected.amount,
          }
        }
        return {
          ...item,
          selected: false,
        }
      })
    }

    if (type === "ingredient") {
      filteredContent = filteredContent.map((item) => {
        let isSelected = false
        if (!!selected.restrictions) {
          isSelected = selected.restrictions.find(({ uuid_ingredient }) => uuid_ingredient === item.uuid_ingredient)
        } else {
          isSelected = selected.find(({ uuid_ingredient }) => uuid_ingredient === item.uuid_ingredient)
        }

        if (!!isSelected) {
          return {
            ...item,
            selected: true,
            amount: isSelected.amount,
          }
        }
        return {
          ...item,
          selected: false,
        }
      })
    }

    if (type === "complement") {
      filteredContent = filteredContent.map((item) => {
        const complementSelectedIndex = selected.findIndex((selectedComplements) => {
          return !!selectedComplements.complements
            ? !!selectedComplements.complements.find(({ uuid_additional }) => uuid_additional === item.uuid_additional)
            : !!selectedComplements.additionals.find(({ uuid_additional }) => uuid_additional === item.uuid_additional)
        })

        if (complementSelectedIndex !== -1) {
          const selectedAdditional = selected[complementSelectedIndex].additionals.find(
            ({ uuid_additional }) => uuid_additional === item.uuid_additional
          )
          if (!!selectedAdditional) {
            return {
              ...item,
              selected: true,
              amount: selectedAdditional.amount,
            }
          }
        }

        return {
          ...item,
          selected: false,
        }
      })

      filteredContent.sort((a, b) => a.nro_priority - b.nro_priority)
    }

    if (type === "flavour") {
      filteredContent = filteredContent.map((item) => {
        const isSelected = selected.find(({ uuid_flavour }) => uuid_flavour === item.uuid_flavour)

        if (!!isSelected) {
          return {
            ...item,
            selected: true,
            amount: isSelected.amount,
            loadingIngredient: isSelected.loadingIngredient,
            loadingOptional: isSelected.loadingOptional,
          }
        }
        return {
          ...item,
          selected: false,
        }
      })
    }

    if (type === "optional") {
      filteredContent = filteredContent.map((item) => {
        if (!!selected.additions) {
          const isSelected = selected.additions.find((ingred) => ingred.uuid_ingredient === item.uuid_ingredient)

          if (!!isSelected) {
            return {
              ...item,
              selected: true,
              amount: isSelected.amount,
            }
          }
        } else {
          const isSelected = selected.find((ingred) => ingred.uuid_ingredient === item.uuid_ingredient)

          if (!!isSelected) {
            return {
              ...item,
              selected: true,
              amount: isSelected.amount,
            }
          }
        }

        return {
          ...item,
          selected: false,
        }
      })
    }

    if (type === "beverage") {
      filteredContent = filteredContent.filter(({ st_website }) => st_website)
      filteredContent = filteredContent.map((item) => {
        const isSelected =
          !!selected &&
          selected.find((selectedBeverage) => {
            const selectedOnCombo = !!nro_item ? selectedBeverage.nro_item === nro_item : true

            return selectedBeverage.uuid_beverage === item.uuid_beverage && selectedOnCombo
          })

        if (!!isSelected) {
          return {
            ...item,
            selected: true,
            amount: isSelected.amount,
          }
        }
        return {
          ...item,
          selected: false,
        }
      })
    }

    if (type === "group") {
      filteredContent = filteredContent.map((item) => {
        return {
          ...item,
          title: item?.complement?.nm_complement,
          selected: item.complementId === selectedGroup,
        }
      })

      filteredContent.sort((a, b) => a.nro_priority - b.nro_priority)
    }

    if (filteredContent.length === 1 && type === "flavour" && !hasClickedRef.current) {
      setTimeout(() => {
        hasClickedRef.current = true
        const optionType = defineOptionType()

        if (optionType === "radio") {
          onClick(filteredContent[0], selected, !!nro_item ? nro_item : complementId, complement, optionType)
        }
      }, 500)
    }
  }, [content, selectFlavour])

  return (
    <div className="collapse-item">
      <div className={"collapse-header " + getClassValidation()} onClick={onChangeCollapse}>
        <Label className="lbl-info">
          <span className="title">
            {title}
            <span className="nro-item">{!!nro_item ? `(Item ${GeneralTools.setNumber(nro_item, 2)})` : ""}</span>
          </span>
          <span className="subtitle">{subtitle}</span>
        </Label>

        <div className="collapse-header-options">
          {!!option ? <Label className="lbl-option">{option}</Label> : ""}
          {!!status && (
            <Label className="lbl-collapse" onClick={onChangeCollapse}>
              {statusCollapse === true ? <FontAwesomeIcon icon="chevron-down" /> : <FontAwesomeIcon icon="chevron-left" />}
            </Label>
          )}
        </div>
      </div>

      <Collapse className={"collapse-content " + (!statusCollapse ? "collapse-hidden" : "")} isOpen={statusCollapse}>
        {type === "flavour" && !contentGraph && content?.length > 5 ? (
          <div className="collapse-content-search-input">
            <Input onChange={(event) => setFilterConsult(event.target.value)} placeholder="Procure por um sabor..." />
          </div>
        ) : (
          ""
        )}
        {(() => {
          if (type === "flavour" && !!contentGraph) {
            let label = "Selecione um sabor..."
            let seriesChart = []
            let labelsChart = []
            let imagesChart = []
            let flavourNumbers = [...Array(getAmountFlavoursSize(size)).keys()]

            let {
              optionsChart,
              onDeleteFlavour,
              onClickComboFlavour,
              selectedComboFlavourNumber,
              onChangeSelectedComboFlavourNumber,
              setModalIngredients,
              getWidthChart,
            } = contentGraph

            // Ensure we have valid data
            if (!selectedComboFlavourNumber || selectedComboFlavourNumber.number === undefined) {
              selectedComboFlavourNumber = { number: 0 } // Default value to prevent errors
            }

            // Generate chart data with proper error checking
            for (let index = 0; index < selectedComboFlavourNumber.number + 1; index++) {
              const flavour = selected && Array.isArray(selected) ? selected.find((item) => item && item.index === index) : null

              // Use a reliable fallback method
              let imageUrl = pizzaStandard
              if (flavour && flavour.url_cover_image) {
                try {
                  imageUrl = getUrlAWSManagerFile(flavour.url_cover_image, noFlavour)
                } catch (err) {
                  console.error("Error getting image URL:", err)
                  imageUrl = noFlavour
                }
              }

              imagesChart[index] = imageUrl
              labelsChart[index] = !!flavour ? flavour.nm_flavour || label : label
              seriesChart.push(100 / (selectedComboFlavourNumber.number + 1))
            }

            // Create a working copy of options to avoid mutation issues
            const newOptionsChart = {
              ...(optionsChart || {}),
              chart: {
                ...(optionsChart?.chart || {}),
                events: {
                  dataPointSelection: (event, chartContext, config) => {
                    if (!!size) {
                      onClickComboFlavour(event, chartContext, config, nro_item)
                    } else {
                      onMessageDialog()
                        .fire({
                          title: "Selecione o tamanho para escolher os sabores!",
                          icon: "warning",
                          timer: 3000,
                          showCancelButton: false,
                          showConfirmButton: false,
                        })
                        .then(() => {})
                    }
                  },
                },
                width: typeof getWidthChart === "function" ? getWidthChart() : 300, // Fallback width if function fails
              },
              labels: labelsChart,
              fill: {
                ...(optionsChart?.fill || {}),
                image: {
                  ...(optionsChart?.fill?.image || {}),
                  src: imagesChart,
                },
              },
              stroke: {
                ...(optionsChart?.stroke || {}),
                show: selectedComboFlavourNumber.number > 0,
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width:
                        typeof getWidthChart === "function"
                          ? Math.min(getWidthChart(), window.innerWidth - 40) // Ensure it fits on mobile
                          : 280,
                    },
                  },
                },
              ],
            }

            // Remove console.log in production
            if (process.env.NODE_ENV !== "production") {
              console.log("optionsChart", newOptionsChart)
            }

            return (
              <div className="builder-graph">
                {!!size ? (
                  <>
                    <Label className="lbl-flavour-numbers">Quantidade de sabores</Label>

                    <div className="flavour-numbers">
                      {flavourNumbers.map((number, key) => (
                        <Button
                          key={key}
                          disabled={!(!!size && size.qty_min_flavours > 0 && number >= size.qty_min_flavours - 1)}
                          className={selectedComboFlavourNumber.number === number ? "active" : ""}
                          onClick={() => {
                            if (typeof onChangeSelectedComboFlavourNumber === "function") {
                              onChangeSelectedComboFlavourNumber(number)
                            }
                          }}
                        >
                          {number + 1}
                        </Button>
                      ))}
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="flavours-graph">
                  {(() => {
                    if (selected && selected.length > 0) {
                      return selected.map((flavour, key) => (
                        <div className="flavour-items" key={key}>
                          <Label
                            className="flavour-description"
                            onClick={() => {
                              if (typeof setModalIngredients === "function") {
                                setModalIngredients({
                                  size,
                                  status: true,
                                  content: {
                                    flavour,
                                    size,
                                    nro_item: contentGraph.nro_item,
                                    itemIndex: flavour.index,
                                  },
                                })
                              }
                            }}
                          >
                            {flavour.nm_flavour || "Sabor"}&nbsp;
                            {flavour.loadingIngredient || flavour.loadingOptional ? <Spinner color="secondary" size="sm" /> : ""}
                          </Label>
                          <label className="flavour-delete">
                            <FontAwesomeIcon
                              className="icon"
                              icon="times-circle"
                              onClick={(event) => {
                                if (typeof onDeleteFlavour === "function") {
                                  onDeleteFlavour(event, flavour.id_flavour, contentGraph.nro_item)
                                }
                              }}
                            />
                          </label>
                        </div>
                      ))
                    } else {
                      return <Label className="lbl-no-flavours">Sem sabores selecionados</Label>
                    }
                  })()}
                </div>

                <div className="chart-graph">
                  <Chart
                    options={newOptionsChart}
                    series={seriesChart}
                    type="pie"
                    width={typeof getWidthChart === "function" ? getWidthChart() : 300}
                    className="cursor-pointer pie-chart"
                  />
                </div>

                <Label className="lbl-info-ingredients">
                  <FontAwesomeIcon icon="info-circle" />
                  &nbsp;Clique no sabor para adicionar ou remover ingredientes.
                </Label>

                {(!selected || selected.length === 0) && (
                  <div className="cursor">
                    <img src={cursor} alt="icone de cursor" />
                  </div>
                )}
              </div>
            )
          } else {
            let filteredContent = content

            if (!!filterConsult) {
              filteredContent = content.filter((item) =>
                `${escapeSpecialChart(item.nm_flavour.toLowerCase())}`.includes(`${escapeSpecialChart(filterConsult.toLowerCase())}`)
              )
            }

            //filtrando os selecionados
            if (type === "size") {
              filteredContent = filteredContent.map((item) => {
                const isSelected = !!selected && item.uuid_size === selected.uuid_size

                if (!!isSelected) {
                  return {
                    ...item,
                    selected: true,
                    amount: isSelected.amount,
                  }
                }
                return {
                  ...item,
                  selected: false,
                }
              })
            }

            if (type === "adicional") {
              filteredContent = filteredContent.map((item) => {
                const isSelected = selected.find(({ uuid_additional }) => uuid_additional === item.uuid_additional)

                if (!!isSelected) {
                  return {
                    ...item,
                    selected: true,
                    amount: isSelected.amount,
                  }
                }
                return {
                  ...item,
                  selected: false,
                }
              })
            }

            if (type === "ingredient") {
              filteredContent = filteredContent.map((item) => {
                let isSelected = false
                if (!!selected.restrictions) {
                  isSelected = selected.restrictions.find(({ uuid_ingredient }) => uuid_ingredient === item.uuid_ingredient)
                } else {
                  isSelected = selected.find(({ uuid_ingredient }) => uuid_ingredient === item.uuid_ingredient)
                }

                if (!!isSelected) {
                  return {
                    ...item,
                    selected: true,
                    amount: isSelected.amount,
                  }
                }
                return {
                  ...item,
                  selected: false,
                }
              })
            }

            if (type === "complement") {
              filteredContent = filteredContent.map((item) => {
                const complementSelectedIndex = selected.findIndex((selectedComplements) => {
                  return !!selectedComplements.complements
                    ? !!selectedComplements.complements.find(({ uuid_additional }) => uuid_additional === item.uuid_additional)
                    : !!selectedComplements.additionals.find(({ uuid_additional }) => uuid_additional === item.uuid_additional)
                })

                if (complementSelectedIndex !== -1) {
                  const selectedAdditional = selected[complementSelectedIndex].additionals.find(
                    ({ uuid_additional }) => uuid_additional === item.uuid_additional
                  )
                  if (!!selectedAdditional) {
                    return {
                      ...item,
                      selected: true,
                      amount: selectedAdditional.amount,
                    }
                  }
                }

                return {
                  ...item,
                  selected: false,
                }
              })

              filteredContent.sort((a, b) => a.nro_priority - b.nro_priority)
            }

            if (type === "flavour") {
              filteredContent = filteredContent.map((item) => {
                const isSelected = selected.find(({ uuid_flavour }) => uuid_flavour === item.uuid_flavour)

                if (!!isSelected) {
                  return {
                    ...item,
                    selected: true,
                    amount: isSelected.amount,
                    loadingIngredient: isSelected.loadingIngredient,
                    loadingOptional: isSelected.loadingOptional,
                  }
                }
                return {
                  ...item,
                  selected: false,
                }
              })
            }

            if (type === "optional") {
              filteredContent = filteredContent.map((item) => {
                if (!!selected.additions) {
                  const isSelected = selected.additions.find((ingred) => ingred.uuid_ingredient === item.uuid_ingredient)

                  if (!!isSelected) {
                    return {
                      ...item,
                      selected: true,
                      amount: isSelected.amount,
                    }
                  }
                } else {
                  const isSelected = selected.find((ingred) => ingred.uuid_ingredient === item.uuid_ingredient)

                  if (!!isSelected) {
                    return {
                      ...item,
                      selected: true,
                      amount: isSelected.amount,
                    }
                  }
                }

                return {
                  ...item,
                  selected: false,
                }
              })
            }

            if (type === "beverage") {
              filteredContent = filteredContent.filter(({ st_website }) => st_website)
              filteredContent = filteredContent.map((item) => {
                const isSelected =
                  !!selected &&
                  selected.find((selectedBeverage) => {
                    const selectedOnCombo = !!nro_item ? selectedBeverage.nro_item === nro_item : true

                    return selectedBeverage.uuid_beverage === item.uuid_beverage && selectedOnCombo
                  })

                if (!!isSelected) {
                  return {
                    ...item,
                    selected: true,
                    amount: isSelected.amount,
                  }
                }
                return {
                  ...item,
                  selected: false,
                }
              })
            }

            if (type === "group") {
              filteredContent = filteredContent.map((item) => {
                return {
                  ...item,
                  title: item?.complement?.nm_complement,
                  selected: item.complementId === selectedGroup,
                }
              })

              filteredContent.sort((a, b) => a.nro_priority - b.nro_priority)
            }

            return filteredContent.map((item, key) => {
              return (
                <OptionCollapseItem
                  key={key + "-" + type + nro_item}
                  selectedOptionals={getSelectedAmountOptions()}
                  onChangeIngredients={(flavour) => onChangeIngredients(flavour, nro_item, comboProductId)}
                  selectedFlavourNumber={selectedFlavourNumber}
                  selectedFlavour={selectedFlavour}
                  selectedComplement={complement}
                  optionType={defineOptionType()}
                  changeAdditionals={() => changeAdditionals(item)}
                  onClick={() => {
                    onClick(
                      item,
                      selected,
                      !!nro_item ? nro_item : !!complementId ? complementId : selectedFlavour?.id_flavour,
                      complement,
                      defineOptionType()
                    )
                  }}
                  onChangeAmount={(amount, direction) => onChangeAmountValue(amount, direction, type, item)}
                  type={type}
                  size={type !== "beverage" ? size : qtyItems}
                  item={item}
                  isCombo={!!comboProductId}
                />
              )
            })
          }
        })()}
      </Collapse>
    </div>
  )
}

export default CollapseItem
